<script>
import Layout from "@/views/layouts/horizontal.vue";
import appConfig from "@/app.config";
import ax from '@/helpers/restful/service';

import AlertModal from "@/components/alert-modal.vue";
import ConfirmModal from "@/components/confirm-modal.vue";

export default {
  page: {
    title: "ระบบหลัก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    AlertModal,
    ConfirmModal,
  },
  data() {
    return {
      title: "ระบบหลัก",
      items: [
        {
          text: "ShowShare",
        },
        {
          text: "ตรวจรับรอง",
          active: true,
        },
      ],
      uid: JSON.parse(localStorage.getItem('auth.user')).uid,
      loaded: true,
      med_type: [],
      courses_inspection: {
        course_inspection_validate_registered_user: [],
        course_inspection_validate_listed_user: [],
        course_inspection_validate_verified_user: [],
        course_inspection_validate_certified_user: [],
      },
      can_save: false,
      isShowConfirmModal: false,
      isSaveSuccessModal: false,
      isSaveFailedModal: false,
      isNoticeModal: false,
      noticeTitleMsg: '',
      noticeTextMsg: '',

      update_data: {
        course_inspection_id: null,
        course_inspection_type: null,
      },
    };
  },
  computed: {},
  watch: {

  },
  async mounted() {
    document.body.classList.add('authentication-bg');
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.loaded = false;
      this.med_type = [];
      this.can_save = false;
      this.isShowConfirmModal = false;
      this.isSaveSuccessModal = false;
      this.isSaveFailedModal = false;

      const r = await Promise.all([
        this.getMedType(),
        this.getUserInfo(),
        this.getMyCoursesInspectionApporve(),
      ]);
      if (r.every(e => e)) {
        this.loaded = true;
        return;
      }
      console.log('loadData error:', r);
    },
    async fileUploadSelect(evt) {
      console.log(evt);
      console.log('fileUploadSelect');
      if (evt.target.files.length > 0) {
        const file = evt.target.files[0];
        console.log('file:', file);
        if (file.type != 'application/pdf') {
          alert('ไฟล์ต้องเป็น PDF เท่านั้น');
          return;
        }
        const formData = new FormData();
        formData.append('file', file);
        const resp = await ax.postForm("/upload-pdf-file", formData);
        console.log("resp fileupload:", resp);
        if (resp.file_name) {
          this.file_upload_url = resp.file_name;
          this.create_data.course_inspection_file = resp.file_name;
          return;
        }
        this.file_upload_url = null;
        this.create_data.course_inspection_file = null;
        return;
      }
    },
    async getUserInfo() {
      const resp = await ax.post("/get-latest-user-info", { uid: this.uid });
      console.log("resp:", resp);
      if (resp.status != 200) {
        return false;
      }
      this.currentUser = resp.data;
      console.log("currentUser:", this.currentUser);
      return true;
    },
    async getMedType() {
      const resp = await ax.post("/get-med-type", {});
      console.log("med type:", resp);

      if (resp.status == 200) {
        this.med_type = resp.data.med_type.map((e) => {
          return {
            value: e.med_type_id,
            text: e.med_type_name,
          };
        });
        return true;
      }
      this.med_type = [];
      return false;
    },
    async getMyCoursesInspectionApporve() {
      const resp = await ax.post("/get-courses-inspection-approve", { uid: this.uid });
      console.log("get-courses-inspection-approve:", resp);

      if (resp.status == 200) {
        this.courses_inspection = resp.data;
        return true;
      }
      this.courses_inspection = {};
      return false;
    },
    async approveRegistedCourse(course_inspection_id) {
      this.update_data.course_inspection_id = course_inspection_id;
      this.update_data.course_inspection_type = 'course_inspection_validate_registered_dt';
      this.isShowConfirmModal = true;
      return;
    },
    async approveListedCourse(course_inspection_id) {
      this.update_data.course_inspection_id = course_inspection_id;
      this.update_data.course_inspection_type = 'course_inspection_validate_listed_dt';
      this.isShowConfirmModal = true;
      return;
    },
    async approveVerifiedCourse(course_inspection_id) {
      this.update_data.course_inspection_id = course_inspection_id;
      this.update_data.course_inspection_type = 'course_inspection_validate_verified_dt';
      this.isShowConfirmModal = true;
      return;
    },
    async approveCertifiedCourse(course_inspection_id) {
      this.update_data.course_inspection_id = course_inspection_id;
      this.update_data.course_inspection_type = 'course_inspection_validate_certified_dt';
      this.isShowConfirmModal = true;
      return;
    },
    async confirmApproveCourse() {
      const resp = await ax.post("/approve-course-inspection", this.update_data);
      console.log("approve-course-inspection:", resp);

      await this.loadData();
      return;
    },
  },
};
</script>

<template>
  <div>
    <Layout layout="horizontal" v-if="loaded">
      <div class="container">
        <div class="row">
          <div class="col-12 table-responsive">
            <table class="mb-5 table text-center">
              <thead>
                <tr>
                  <th scope="col" class="bg-success text-white">แบบคำขอขึ้นทะเบียน สำหรับการขอรับรองการจดทะเบียน (Registered)</th>
                  <th scope="col" class="bg-success text-white">ข้อมูลผู้ยื่นคำขอ</th>
                  <th scope="col" class="bg-success text-white">ตรวจสอบเนื้อหา และอนุมัติ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in courses_inspection.course_inspection_validate_registered_user" :key="index">
                  <td>{{ 'VS' + item.course_inspection_uid.toString().padStart(6, '0') }} {{ item.course_inspection_name
                  }}</td>
                  <td>
                    <a :href="item.course_inspection_file" target="_blank">แบบคำขอ #{{ item.course_inspection_id }}</a>
                  </td>
                  <td>
                    <div v-if="item.course_inspection_validate_registered_dt == null" class="d-grid">
                      <button class="btn btn-primary" type="button" @click="approveRegistedCourse(item.course_inspection_id)">อนุมัติ</button>
                    </div>
                    <div v-if="item.course_inspection_validate_registered_dt" class="d-grid">
                      <button class="btn btn-success" type="button">อนุมัติแล้ว {{ new Date(item.course_inspection_validate_registered_dt).toLocaleDateString('th-TH') }}</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            
            <table class="mb-5 table text-center">
              <thead>
                <tr>
                  <th scope="col" class="bg-success text-white">แบบคำขอขึ้นทะเบียน สำหรับการขอรับรองการขึ้นทะเบียน (Listed)</th>
                  <th scope="col" class="bg-success text-white">ข้อมูลผู้ยื่นคำขอ</th>
                  <th scope="col" class="bg-success text-white">ตรวจสอบเนื้อหา และอนุมัติ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in courses_inspection.course_inspection_validate_listed_user" :key="index">
                  <td>{{ 'VS' + item.course_inspection_uid.toString().padStart(6, '0') }} {{ item.course_inspection_name
                  }}</td>
                  <td>
                    <a :href="item.course_inspection_file" target="_blank">แบบคำขอ #{{ item.course_inspection_id }}</a>
                  </td>
                  <td>
                    <div v-if="item.course_inspection_validate_listed_dt == null" class="d-grid">
                      <button class="btn btn-primary" type="button" @click="approveListedCourse(item.course_inspection_id)">อนุมัติ</button>
                    </div>
                    <div v-if="item.course_inspection_validate_listed_dt" class="d-grid">
                      <button class="btn btn-success" type="button">อนุมัติแล้ว {{ new Date(item.course_inspection_validate_listed_dt).toLocaleDateString('th-TH') }}</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            
            <table class="mb-5 table text-center">
              <thead>
                <tr>
                  <th scope="col" class="bg-success text-white">แบบคำขอขึ้นทะเบียน สำหรับการขอรับรองการตรวจสอบ (Verified)</th>
                  <th scope="col" class="bg-success text-white">ข้อมูลผู้ยื่นคำขอ</th>
                  <th scope="col" class="bg-success text-white">ตรวจสอบเนื้อหา และอนุมัติ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in courses_inspection.course_inspection_validate_verified_user" :key="index">
                  <td>{{ 'VS' + item.course_inspection_uid.toString().padStart(6, '0') }} {{ item.course_inspection_name
                  }}</td>
                  <td>
                    <a :href="item.course_inspection_file" target="_blank">แบบคำขอ #{{ item.course_inspection_id }}</a>
                  </td>
                  <td>
                    <div v-if="item.course_inspection_validate_verified_dt == null" class="d-grid">
                      <button class="btn btn-primary" type="button" @click="approveVerifiedCourse(item.course_inspection_id)">อนุมัติ</button>
                    </div>
                    <div v-if="item.course_inspection_validate_verified_dt" class="d-grid">
                      <button class="btn btn-success" type="button">อนุมัติแล้ว {{ new Date(item.course_inspection_validate_verified_dt).toLocaleDateString('th-TH') }}</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            
            <table class="mb-5 table text-center">
              <thead>
                <tr>
                  <th scope="col" class="bg-success text-white">แบบคำขอขึ้นทะเบียน สำหรับการขอรับรองการตรวจรับรอง (Certified))</th>
                  <th scope="col" class="bg-success text-white">ข้อมูลผู้ยื่นคำขอ</th>
                  <th scope="col" class="bg-success text-white">ตรวจสอบเนื้อหา และอนุมัติ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in courses_inspection.course_inspection_validate_certified_user" :key="index">
                  <td>{{ 'VS' + item.course_inspection_uid.toString().padStart(6, '0') }} {{ item.course_inspection_name
                  }}</td>
                  <td>
                    <a :href="item.course_inspection_file" target="_blank">แบบคำขอ #{{ item.course_inspection_id }}</a>
                  </td>
                  <td>
                    <div v-if="item.course_inspection_validate_certified_dt == null" class="d-grid">
                      <button class="btn btn-primary" type="button" @click="approveCertifiedCourse(item.course_inspection_id)">อนุมัติ</button>
                    </div>
                    <div v-if="item.course_inspection_validate_certified_dt" class="d-grid">
                      <button class="btn btn-success" type="button">อนุมัติแล้ว {{ new Date(item.course_inspection_validate_certified_dt).toLocaleDateString('th-TH') }}</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="my-5">

            </div>
          </div>
        </div>
      </div>
    </Layout>

    <ConfirmModal :isShow="isShowConfirmModal" v-on:close="isShowConfirmModal = false;"
      v-on:confirm="isShowConfirmModal = false; confirmApproveCourse();" title="ยืนยันการอนุมัติ"
      :text="`คำขอหมายเลข ${update_data.course_inspection_id} ได้รับการตรวจสอบ และส่งคำสั่งอนุมัติเพื่อบันทึกผล ?`">
    </ConfirmModal>

    <AlertModal :isShow="isSaveSuccessModal" title="ส่งคำขอสำเร็จ" text="ข้อมูลคำขอถูกบันทึกแล้ว..."
      v-on:close="isSaveSuccessModal = false; loadData();"></AlertModal>
    <AlertModal :isShow="isSaveFailedModal" title="ส่งคำขอไม่สำเร็จ" text="กรุณาลองใหม่อีกครั้ง..."
      v-on:close="isSaveFailedModal = false"></AlertModal>

    <AlertModal :isShow="isNoticeModal" :title="noticeTitleMsg" :text="noticeTextMsg" v-on:close="isNoticeModal = false">
    </AlertModal>

</div></template>